<template lang="pug">
div
  .form-horizontal
    .row
      .col
        label.col-sm-2.control-label.no-padding-right(for='pesquisa')  Produto
        .col-sm-4
          input(type='text', v-model='pesquisar', placeholder='Filtro por Cod ou Nome')
      .col
        .col-sm-6
          select-form(v-model='venda.categoria', :value='venda.categoria', name='Categoria', url='/produtos/categoria', id='id', titulo='titulo', layout='true', :required='false')
  br
  div
    kendo-datasource(:page-size="8", ref="datasource1", :transport-read="{ url: `${$url}/produtos`, beforeSend: onBeforeSend}", :filter="filter")
    kendo-listview.row(:data-source-ref="'datasource1'", :template="itemTemplate")
    kendo-pager(:data-source-ref="'datasource1'", :page-sizes="false")
    br
    .alert.alert-danger.alert-dismissible.fade.in.text-center(role='alert', v-if='!produtosSelecionados.length')
      button.close(type='button', data-dismiss='alert', aria-label='Close')
        span(aria-hidden='true') ×
      | Nenhum Produto Adicionado!

    table.table.table-striped.table-bordered.table-hover(v-else='')
      thead
        tr
          th.text-center Produto
          th.text-center Obs
          th.text-center Preço
          th.text-center Quantidade
          th
      tbody
        tr(v-for='prodsel in produtosSelecionados')
          td {{prodsel.nome}}
          td
            textarea(v-model='prodsel.obs')
          td.text-right
            span(style='text-decoration: line-through') {{prodsel.preco1 !== prodsel.preco ? prodsel.preco1 : ''}}
            money(type='text', v-model='prodsel.preco', v-bind='money')
          td.text-center
            button.btn.btn-xs.btn-danger(@click.prevent='remove(prodsel)', :disabled='prodsel.quant <= 1')
              i.fa.fa-minus-circle(aria-hidden='true')
            input.moedanova(type='text', v-model='prodsel.quant', style='width: 50px')
            button.btn.btn-xs.btn-success(@click.prevent='add(prodsel)')
              i.fa.fa-plus-circle(aria-hidden='true')
          td.text-center
            a.btn.btn-danger.btn-mini(href='#', @click.prevent='removeOne(prodsel)') Apagar
        tr#cart-table-footer
          td.text-right(colspan='5')
            | Total:
            b
              | R$ {{total | Decimal}}
        tr#cart-actions
          td.text-right(colspan='5')
            input#empty.btn.btn-mini.btn-danger(type='button', value='Limpar', @click.prevent='removeAll')

</template>

<script>
import Vue from 'vue'
import {Money} from 'v-money'
import '@progress/kendo-ui'
import '@progress/kendo-theme-default/dist/all.css'
import SelectForm from '@/components/form/SelectForm'
import { DataSource, DataSourceInstaller } from '@progress/kendo-datasource-vue-wrapper'
import { ListView, Pager, ListViewInstaller } from '@progress/kendo-listview-vue-wrapper'
import num from 'numeral'

Vue.use(DataSourceInstaller)
Vue.use(ListViewInstaller)

export default {
  components: {
    SelectForm,
    DataSource,
    ListView,
    Pager,
    Money
  },
  filters: {
    Decimal (numero, i = 2) {
      const x = numero
      const y = x.toFixed(i)
      const z = y.split('.')
      // eslint-disable-next-line no-useless-escape
      const a = num(z[0]).format('0,0').replace(/\,/g, '.')
      return z.length > 1 ? `${a},${z[1]}` : a
    }
  },
  props: ['categoria'],
  computed: {
    total () {
      let totalgeral = 0
      for (var idx in this.produtosSelecionados) {
        let currentItem = this.produtosSelecionados[idx]
        totalgeral += parseInt(currentItem.quant) * currentItem.preco
      }
      return totalgeral
    },
    filter () {
      return {
        'logic': 'and',
        'filters': [{
          'field': 'titulo',
          'operator': 'contains',
          'value': this.pesquisar
        }, {
          'field': 'categoria_id',
          'operator': 'contains',
          'value': this.venda.categoria
        }]
      }
    }
  },
  watch: {
    'venda.categoria' () {
      this.$emit('setCategoria', this.venda.categoria)
    },
    produtosSelecionados () {
      this.$emit('select', this.produtosSelecionados)
    }
  },
  data () {
    return {
      pesquisar: '',
      venda: {
        categoria: ''
      },
      produtosSource: [],
      fornecedorCat: [],
      produtosSelecionados: [],
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 2,
        masked: false /* doesn't work with directive */
      }
    }
  },
  methods: {
    adicionarProduto (id, prod, preco, preco3) {
      let quant = 1
      let nome = `${id} - ${prod}`
      for (let index in this.produtosSelecionados) {
        let Item = this.produtosSelecionados[index]
        if (id === Item.id) {
          quant = parseInt(Item.quant) + 1
          // eslint-disable-next-line no-return-assign
          return Item.quant = quant
        }
      }
      this.produtosSelecionados.push({
        id,
        nome,
        preco: Number(preco),
        preco3: Number(preco3),
        quant
      })
    },
    removeOne (prodsel) {
      let index = this.produtosSelecionados.indexOf(prodsel)
      this.produtosSelecionados.splice(index, 1)
    },
    removeAll () {
      this.produtosSelecionados = []
    },
    add (prodsel) {
      prodsel.quant++
    },
    remove (prodsel) {
      prodsel.quant--
      if (prodsel.quant <= 0) {
        var index = this.produtosSelecionados.indexOf(prodsel)
        this.produtosSelecionados.splice(index, 1)
      }
    },
    itemTemplate (e) {
      var vm = this
      return {
        template: Vue.component('temp', {
          template: `
            <div class="col-sm-3">
              <div class="widget-box">
                <div class="widget-header">
                  <h5 class="widget-title smaller ui-sortable-handle">{{ templateArgs.valor }}</h5>
                  <div class="widget-toolbar">
                    <a href="javascript:void(0)" class="btn btn-success" @click="adicionarProduto(templateArgs)">
                      <i class="fa fa-plus-circle"></i>
                    </a>
                  </div>
                </div>
                <div class="widget-body">
                  <div class="widget-main padding-6 text-center">
                    <div style="height:55px;">
                      {{templateArgs.id}} - {{templateArgs.titulo}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          `,
          data () {
            return {
              templateArgs: {}
            }
          },
          methods: {
            adicionarProduto (item) {
              vm.adicionarProduto(item.id, item.titulo, item.valor)
            }
          }
        }),
        templateArgs: e
      }
    },
    getVenda () {
      this.$axios(`/vendas/produtos/${this.$route.params.idVenda}`).then(resp => {
        this.produtosSelecionados = resp.data.map(e => {
          e.idp = e.id
          e.id = e.produto_id
          e.nome = e.produto_nome
          e.preco = Number(e.produto_preco)
          e.preco3 = Number(e.produto_preco)
          e.quant = e.produto_qtd
          e.obs = e.produto_obs
          return e
        })
      })
    },
    onBeforeSend (xhr) {
      xhr.setRequestHeader(
        'Authorization',
        'Bearer ' + localStorage.getItem('token')
      )
    }
  },
  mounted () {
    this.$refs.datasource1.kendoDataSource.read()
    this.venda.categoria = this.categoria
    if (this.$route.params.idVenda) {
      this.getVenda()
    }
  }
}
</script>

<style lang="stylus" scoped>
.k-widget
  border-width 0px
</style>
